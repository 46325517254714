<template>
  <div>
    <el-row v-if="!pageStatus.error" v-loading="isUploading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Өнгө засварлах'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="colorData" :rules="colorRules" ref="colorData">
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="name_mon">
                        <el-input v-model="colorData.name_mon" placeholder="Цагаан ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="name_eng">
                      <el-input v-model="colorData.name_eng" placeholder="White ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Өнгө сонгох">
                      <el-color-picker v-model="color" @change="onChangeColor"></el-color-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Сонгосон өнгө" >
                        <div class="image-holder" :style="`background-color:${color}; width:80px; heigth:64px`"  >
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Өнгөний код" prop="hex_code">
                        <el-input disabled v-model="colorData.hex_code" placeholder="#6BD90B"></el-input>
                      </el-form-item>
                    </el-col>
                    </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button class="text-left" type="danger" @click="onChange('delete')">Устгах</el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onChange('update')"
                >Шинэчлэх</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
     <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >Хэрвээ та болих товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Үргэлжлүүлэх</el-button
        >
        <router-link :to="'/colorList?' + 'search=' + this.$route.query.search + '&page=' + this.$route.query.page + '&size=' + this.$route.query.size">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Болих</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- delete -->
    <el-dialog
      :title="isChanging ? 'Өнгө шинэчлэх' : 'Өнгө устгах'"
      :visible.sync="confirmColorDialog"
      width="50%"
    >
      <div>
        <strong>{{colorData.name_mon}}</strong> өнгийг {{ isChanging ? 'шинэчлэхдээ' : 'устгахдаа'}} шалтгаанаа заавал бичнэ үү?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmColorDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button  v-if="isChanging" :disabled="isDisabled" type="success" @click="onPressNewColor('colorData', 'colorRules')">
          Хадгалах
        </el-button>
        <el-button v-else type="danger" :disabled="isDisabled" @click="deleteColor('colorData', 'colorRules')">
          Устгах
        </el-button>
      </span>
      <el-row :gutter="20">
        <el-col :span="24" class="mt20">
          <el-form :model="colorData" :rules="colorRules" ref="colorData">
            <el-form-item prop="desc" label="Шалтгаан">
              <el-input v-model="colorData.desc" type="textarea" :rows="2" :placeholder="isChanging ? 'Шинэчилж буй шалтгаанаа бичнэ үү ...' : 'Устгаж буй шалтгаанаа бичнэ үү ...'"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
export default {
  name: 'colorUpdateForm',
  components: {
    customHeader: customHeader
  },

  created () {
    if (this.$route.params.color_id) {
      this.getOneColor(this.$route.params.color_id)
    }
  },

  data () {
    return {
      isDisabled: false,
      isChanging: '',
      color: '',
      tempImages: [],
      isUploading: false,
      loading: false,
      confirmColorDialog: false,
      confirmLeaveForm: false,
      dialogVisible: false,
      dialogImageUrl: '',
      supplierOption: [],
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      colorData: {
        desc: '',
        color_id: '',
        name_mon: '',
        name_eng: '',
        hex_code: ''
      },
      colorRules: {
        desc: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        hex_code: [
          { required: true, message: 'Өнгөө сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    onChange (type) {
      if (type === 'update') {
        this.confirmColorDialog = true
        this.isChanging = true
      } else {
        this.confirmColorDialog = true
        this.isChanging = false
      }
    },
    onChangeColor (color) {
      this.colorData.hex_code = color
    },

    deleteColor (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          services.deleteColor({
            color_id: this.colorData.color_id,
            desc: this.colorData.desc
          }).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', `${this.colorData.color_id} дугаартай өнгө устлаа`, 'success')
              this.isDisabled = false
              this.loading = false
              this.$router.push({
                name: 'colorList'
              })
            } else if (response.status === 'error') {
              this.alertReporter('Алдаа', `${this.colorData.color_id} дугаартай өнгийг устгахад алдаа гарлаа`, 'error')
              this.loading = false
            } else {
              return false
            }
          })
        }
      })
    },

    onPressNewColor (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          if (this.colorData.color_id !== '') {
            services.updateColor(this.colorData).then(data => {
              this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
              if (data.status === 'success') {
                this.$refs[formName].resetFields()
                this.loading = false
                this.isDisabled = false
                this.$router.push({ name: 'colorList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              } else {
                return false
              }
            })
          }
        } else {
          console.log('error')
        }
      })
    },

    getOneColor (id) {
      services.getOneColor(id).then(response => {
        if (response.status === 'success') {
          this.colorData = response.data
          this.color = response.data.hex_code
          this.loading = false
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
          this.loading = false
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>

<style></style>
